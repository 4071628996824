import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminType, getCanViewDashboard, getIsSuperAdmin, getReferralCode } from '../../features/user/userSlice'
import { getAdminDashboardStatistics } from '../../api/Admin'
import { setLoadingState } from '../../features/loader/LoaderSlice'
import Constants from '../../helpers/Constants'
import Utils from '../../helpers/Utils'
import { Link } from 'react-router-dom'
import { Box, Row, Column, Heading } from '../../components/vanilla'

function Index() {
    // Getting the current admin type
    const adminType = useSelector(getAdminType)
    const referralCode = useSelector(getReferralCode)
    const [dashboardStats, setDashboardStats] = useState([])
    const isSuperAdmin = useSelector(getIsSuperAdmin)
    const canViewDashboard = useSelector(getCanViewDashboard)

    useEffect(() => {
        populateInitData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Init Dispatch here
    const dispatch = useDispatch()

    /** Function to Populate initial data */
    const populateInitData = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Getting all the dashboard statistics
            await getDashboardStats()
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get admin dashboard statistics */
    const getDashboardStats = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            const response = await getAdminDashboardStatistics()
            setDashboardStats(response)
        } catch (error) {
            return error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    return (
        <Box className="admin-container mb-4">
            <h2>CRM</h2>
            {adminType === Constants.TYPE_USER_ADMIN_MAIN ? (
                <>
                    {isSuperAdmin || canViewDashboard ? (
                        <Row className="mb-4 mt-4">
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fa fa-user-secret widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Candidates Applied (
                                            <Link
                                                to={`${Constants.ROUTE_ADMIN_APPLICANTS('applied')}`}
                                                className="text-decoration-none">
                                                View
                                            </Link>
                                            )
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            {dashboardStats.appliedCount}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>

                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fa fa-user-plus widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Candidates Joined
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            {dashboardStats.candidatesJoined}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fas fa-chalkboard-teacher widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Interview In Progress
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            {dashboardStats.interviewsInProgress}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="far fa-building widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Total Jobs
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            10{dashboardStats.jobsTotal}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fas fa-users widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Total Candidates
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            1{dashboardStats.candidatesTotal}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="far fa-question-circle widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Total Internal HRs
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            {dashboardStats.subHrTotal}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fa fa-user-times widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Non Verified Candidates Count (
                                            <Link
                                                to={`${Constants.ROUTE_ADMIN_APPLICANTS_NON_VERIFIED}`}
                                                className="text-decoration-none">
                                                View
                                            </Link>
                                            )
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            {dashboardStats.nonVerifiedJobSeekerCount}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fa fa-user-md widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Pool Candidates Count
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            1{dashboardStats.poolCandidateCount}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                            <Column className="col-md-4 mb-4">
                                <Box className="card">
                                    <Box className="card-body">
                                        <Box className="float-end">
                                            <i className="fa fa-user-secret widget-icon"></i>
                                        </Box>
                                        <Heading size="md" className="fw-normal text-muted mt-0">
                                            Non Pool Candidates Count
                                        </Heading>
                                        <Heading size="xl" className="mt-3 widget-num">
                                            {dashboardStats.nonPoolCandidateCount}
                                        </Heading>
                                    </Box>
                                </Box>
                            </Column>
                        </Row>
                    ) : (
                        <>Welcome Admin</>
                    )}
                </>
            ) : (
                <>
                    <h3>Your Referral code: </h3>
                    <div className="badge bg-danger bg-opacity-10 text-danger">{referralCode}</div>
                    <p>
                        You can use the above mentioned referral code to along with job URL to create your own unique
                        sharable URL!
                    </p>
                </>
            )}
        </Box>
    )
}

export default Index
