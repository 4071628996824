import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllEmployers } from '../../../../api/Admin'
import Pagination from '../../../../components/Pagination'
import ValidationError from '../../../../errors/ValidationError'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import { Box, Button, Column, Heading, Row } from '../../../../components/vanilla'
import {
    useToggleEmployerAccessToJobSeeker,
    useToggleEmployerStatus,
    useToggleEmployerCvAccessStatus
} from '../../../../hooks/useAdmin'
import CompanyHighlightModal from '../../../../components/elements/employer/CompanyHighlightModal'
import { FaAddressCard, FaEdit } from 'react-icons/fa'
import SubscriptionStatusModal from '../../../../components/elements/employer/SubscriptionStatusModal'
function EmployerList() {
    // Initialize dispatch here
    const dispatch = useDispatch()

    // Data Vars
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const [employers, setEmployers] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const [selectedEmployer, setSelectedEmployer] = useState([])
    const [subscribedAgency, setSubscribedAgency] = useState({})

    const { mutateAsync } = useToggleEmployerAccessToJobSeeker()
    const { mutateAsync: changeEmployerStatus } = useToggleEmployerStatus()
    const { mutateAsync: changeCvCommunityAccessStatus } = useToggleEmployerCvAccessStatus()

    useEffect(() => {
        populateInitData()
    }, [])

    /** Function to populate initial data */
    const populateInitData = async () => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            await getEmployersByPage(currentPage)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    const toggleCvCommunityAccess = async (e, employerId, index) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            const checkValue = e.target.checked
            await changeCvCommunityAccessStatus({
                data: {
                    employerId: employerId,
                    cvCommunityAccess: checkValue
                }
            })
            let values = [...employers]
            values[index].cvCommunityAccess = checkValue
            setEmployers(values)
            Utils.showToast(Constants.MSG_CV_COMMUNITY_ACCESS_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    const toggleAccessJobSeekers = async (e, employerId, index) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            const checkValue = e.target.checked
            await mutateAsync({
                data: {
                    employerId: employerId,
                    canAccessJobSeekers: checkValue
                }
            })
            let values = [...employers]
            values[index].canAccessJobSeekers = checkValue
            setEmployers(values)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /**Change employer status to make active or inactive */
    const toggleIsActive = async (e, employerId, index) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            const checkValue = e.target.checked
            await changeEmployerStatus({
                data: {
                    employerId: employerId,
                    isActive: checkValue
                }
            })
            let values = [...employers]
            values[index].isActive = checkValue
            setEmployers(values)
            Utils.showToast(Constants.MSG_STATUS_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get employer member by page number */
    const getEmployersByPage = async (pageNumber) => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            const response = await getAllEmployers('', searchParam, Constants.TYPE_USER_EMPLOYER_COMPANY, pageNumber)

            // Setting the data here
            setCurrentPage(response.currentPage)
            setTotalPages(response.totalPages)
            setTotalCount(response.count)
            setEmployers([...response.employers])
        } catch (error) {
            throw error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            await getEmployersByPage(pageNumber)
        } catch (error) {
            throw error
        }
    }

    const textStyle = {
        fontSize: '13px'
    }

    /** Function to search job seeker by name */
    const searchEmployers = async (e) => {
        try {
            await getEmployersByPage(currentPage)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    const updateSubscriptionEnd = async (data) => {
        let values = [...employers]
        values[data.index].subscriptionEnd = data.subscriptionEnd
        setEmployers(values)
    }

    return (
        <Box className="p-4 mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">Manage Employers</Heading>
                <Link to={Constants.ROUTE_ADMIN_MANAGE_ADD_EMPLOYER} className="btn-job btn-primary">
                    <i className="fas fa-user-plus"></i> Add New
                </Link>
            </Box>

            <Row className="row mb-3 ">
                <Column className="col-sm-12 p-0">
                    <Box className="input-group">
                        <input
                            onChange={(e) => setSearchParam(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Search Employer"
                        />
                        <Button
                            type="button"
                            label="Search"
                            className="btn btn-search btn-primary radius-none"
                            onClick={(e) => searchEmployers(e)}></Button>
                    </Box>
                </Column>
            </Row>

            {employers.map((employer, index) => (
                <Box className="card mb-4" key={employer.id}>
                    <Row className="card-body row align-items-center">
                        <Column className="col-md-1 mb-2 mb-md-0 text-center text-md-start">
                            <img
                                src={`${
                                    employer.avatarPath != ''
                                        ? employer.avatarPath
                                        : Constants.DEFAULT_PATH_EMPLOYER_AVATAR
                                }`}
                                className="avatar-img rounded-circle"
                            />
                        </Column>
                        <Column className="col-md-2 mb-4 mb-md-0 text-center text-md-start">
                            <Heading size="md">{employer.companyName}</Heading>
                            <Box>{employer.email}</Box>
                            <Box>{employer.phoneNumber}</Box>
                        </Column>

                        <Box className=" col-md-2 form-check form-switch">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                role="switch"
                                id={`jobSeekerAccessToggle${index}`}
                                checked={employer.canAccessJobSeekers}
                                onChange={(e) => toggleAccessJobSeekers(e, employer.id, index)}
                                style={{ marginTop: 0 }}
                            />
                            <Box
                                Element="label"
                                className="form-check mb-0 ps-0"
                                style={{ width: '300px', ...textStyle }}>
                                Can access JobSeekers ?
                            </Box>
                        </Box>
                        <Box className=" col-md-1 form-check form-switch">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                role="switch"
                                id={`employerCvCommunityToggle${index}`}
                                checked={employer.cvCommunityAccess}
                                onChange={(e) => toggleCvCommunityAccess(e, employer.id, index)}
                                style={{ marginTop: 0 }}
                            />
                            <Box
                                Element="label"
                                className="form-check mb-0 ps-0"
                                style={{ width: '100px', ...textStyle }}>
                                cvdcn ?
                            </Box>
                        </Box>
                        <Box className=" col-md-1 form-check form-switch">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                role="switch"
                                checked={employer.isActive}
                                onChange={(e) => toggleIsActive(e, employer.id, index)}
                                style={{ marginTop: 0 }}
                            />
                            <Box
                                Element="label"
                                className="form-check mb-0 ps-0"
                                style={{ width: '300px', ...textStyle }}>
                                Active ?
                            </Box>
                        </Box>
                        <Box className="col-md-1">
                            <Button
                                onClick={() => setSelectedEmployer(employer)}
                                data-bs-toggle="modal"
                                data-bs-target="#highlightModal"
                                className="w-100 mb-2"
                                color="success"
                                label="Update Highlights"
                                icon={<FaEdit />}
                            />
                        </Box>
                        <Box className="col-md-2">
                            {employer.companyType === Constants.TYPE_COMPANY_HIRING_AGENCY &&
                                employer.subscriptionEnd === '' && (
                                    <Button
                                        onClick={() =>
                                            setSubscribedAgency({
                                                id: employer.id,
                                                index,
                                                companyName: employer.companyName,
                                                subscribedUntil: ''
                                            })
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#subscriptionModal"
                                        className="w-100 mb-2"
                                        color="primary"
                                        label="Add Subscription"
                                        icon={<FaAddressCard />}
                                    />
                                )}

                            {employer.companyType === Constants.TYPE_COMPANY_HIRING_AGENCY && employer.subscriptionEnd && (
                                <>
                                    {' '}
                                    <Button
                                        onClick={() =>
                                            setSubscribedAgency({
                                                id: employer.id,
                                                index,
                                                companyName: employer.companyName,
                                                subscribedUntil: employer.subscriptionEnd
                                            })
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#subscriptionModal"
                                        className="w-100 mb-2"
                                        color="warning"
                                        label="Update Subscription"
                                        icon={<FaAddressCard />}
                                    />
                                    <Box
                                        Element="label"
                                        className="form-check mb-0 ps-0"
                                        style={{ width: '300px', ...textStyle }}>
                                        <b>Subscribed Until:</b> {employer.subscriptionEnd}
                                    </Box>
                                </>
                            )}
                        </Box>

                        <Column className="col-md-2 text-md-end text-center mb-4 mb-md-0">
                            <Link
                                to={Constants.ROUTE_ADMIN_MANAGE_EMPLOYER_MEMBER_LIST(employer.id)}
                                className="btn-job btn-primary">
                                <i className="fas fa-users"></i> Interviewers/HRs
                            </Link>
                            <a className="btn-job btn-success ms-3" onClick={() => alert('Coming Soon!')}>
                                <i className="fas fa-edit"></i> Edit Profile
                            </a>
                        </Column>
                    </Row>
                </Box>
            ))}

            <CompanyHighlightModal selectedEmployer={selectedEmployer} />
            <SubscriptionStatusModal
                subscribedAgency={subscribedAgency}
                updateSubscriptionEnd={updateSubscriptionEnd}
            />

            {/* Pagination */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={(page) => changePage(page)}
                itemCount={totalCount}
            />
        </Box>
    )
}

export default EmployerList
