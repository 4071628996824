class Constants {
    /** Client Route Constants */
    // 404 Route
    static ROUTE_CLIENT_404 = '/404'
    static ROUTE_CLIENT_ADMIN_404 = '/admin/404'
    // Auth Routes
    static ROUTE_CLIENT_LOGIN_JOB_SEEKER = '/auth/login/user'
    static ROUTE_CLIENT_LOGIN_EMPLOYER = '/auth/login/employer'
    static ROUTE_CLIENT_REGISTER_JOB_SEEKER = '/auth/register/user'
    static ROUTE_CLIENT_REGISTER_EMPLOYER = '/auth/register/employer'
    static ROUTE_CLIENT_FP_JOB_SEEKER = '/auth/forgot-password/user'
    static ROUTE_CLIENT_FP_EMPLOYER = '/auth/forgot-password/employer'
    static ROUTE_CLIENT_FP_ADMIN = '/admin/auth/forgot-password'
    // Employer Routes
    static ROUTE_CLIENT_EMPLOYER_POST_JOB = '/employer/dashboard/job/add'
    static ROUTE_CLIENT_EMPLOYER_JOB_LIST = '/employer/dashboard/job/list'
    static ROUTE_CLIENT_EMPLOYER_VENDOR_LIST = '/employer/dashboard/vendor/list'
    static ROUTE_CLIENT_EMPLOYER_ADD_VENDOR = '/employer/dashboard/vendor/add'
    static ROUTE_CLIENT_EMPLOYER_JOB_EDIT = (jobId) => `/employer/dashboard/job/${jobId}/edit`
    static ROUTE_CLIENT_EMPLOYER_PROSPECT_LIST = '/employer/dashboard/prospect/list'
    static ROUTE_CLIENT_EMPLOYER_PROSPECT_ADD = '/employer/dashboard/prospect/add'
    static ROUTE_CLIENT_EMPLOYER_PANEL_LIST = '/employer/dashboard/panel/list'
    static ROUTE_CLIENT_EMPLOYER_PANEL_ADD = '/employer/dashboard/panel/add'
    static ROUTE_CLIENT_EMPLOYER_PROFILE = '/employer/dashboard/profile'
    static ROUTE_CLIENT_EMPLOYER_INTERVIEWS = '/employer/dashboard/interviews'
    static ROUTE_CLIENT_EMPLOYER_SCHEDULE = '/employer/dashboard/schedule'
    static ROUTE_CLIENT_EMPLOYER_JOB_SEEKER = '/employer/dashboard/job-seekers'
    static ROUTE_CLIENT_EMPLOYER_CHANGE_PWD = '/employer/dashboard/change-password'
    static ROUTE_CLIENT_EMPLOYER_DASHBOARD = '/employer/dashboard/job/dashboard'

    static ROUTE_CLIENT_VENDOR_JOB_SEEKER_LIST = '/vendor/dashboard/job-seeker/list'
    static ROUTE_CLIENT_VENDOR_ADD_JOB_SEEKER = '/vendor/dashboard/job-seeker/add'
    static ROUTE_CLIENT_VENDOR_CANDIDATE_TO_JOB = '/vendor/assignment/job-seeker/job'
    static ROUTE_CLIENT_VENDOR_INTERVIEWS = '/vendor/dashboard/interviews'

    // employer vendor routes
    static ROUTE_CLIENT_EMPLOYER_VENDOR_JOB_SEEKERS_LIST = '/vendor/dashboard/job-seeker/list'

    // Job-seeker routes
    static ROUTE_CLIENT_JOB_SEEKER_INTERVIEWS = '/user/dashboard/interviews'
    static ROUTE_CLIENT_JOB_SEEKER_JOBS = '/user/dashboard/jobs'
    static ROUTE_CLIENT_JOB_SEEKER_DOCUMENTS = '/user/dashboard/documents'
    static ROUTE_CLIENT_JOB_SEEKER_APPLICATIONS = '/user/dashboard/applications'
    static ROUTE_CLIENT_JOB_SEEKER_PROFILE = '/user/profile'
    static ROUTE_CLIENT_JOB_SEEKER_APPLY_JOB = (jobId) => `/user/job/${jobId}/apply`
    static ROUTE_CLIENT_JOB_SEEKER_CHANGE_PWD = '/user/dashboard/change-password'
    // Home Route
    static ROUTE_CLIENT_HOME = '/'
    // Static Routes
    static ROUTE_CLIENT_ABOUT_US = '/about-us'
    static ROUTE_CLIENT_CONTACT_US = '/contact-us'
    static ROUTE_CLIENT_BUY_SERVICES = '/buy-services'
    static ROUTE_CLIENT_SITEMAP = '/sitemap'
    static ROUTE_CLIENT_HIRING_SOLUTIONS = '/hiring-solutions'
    static ROUTE_CLIENT_SITEMAP_SLUG_PAGE = '/sitemap/:slug'
    static ROUTE_POOL_USER_PROFILE_REFERRAL = '/registration/:referralCode'
    static ROUTE_POOL_USER_PROFILE = '/registration'
    static ROUTE_CAMPUS_USER_PROFILE = '/campus-registration'
    // Admin Routes
    static ROUTE_ADMIN_HOME = '/admin/home'
    static ROUTE_ADMIN_LOGIN = '/admin/auth/login'
    static ROUTE_ADMIN_APPLICANTS = (status) => `/admin/applicants/${status}`
    static ROUTE_ADMIN_EDIT_ADMIN = ({ adminId }) => `/admin/manage/admin/${adminId}/edit`
    static ROUTE_ADMIN_ADD_ADMIN = '/admin/manage/admin/add'
    static ROUTE_ADMIN_LIST_ADMIN = '/admin/manage/admin/list'

    static ROUTE_ADMIN_APPLICANTS_NON_VERIFIED = '/admin/job-seeker/non-verified'
    // Admin Job Routes
    static ROUTE_ADMIN_JOBS_ADD = '/admin/jobs/add'
    static ROUTE_ADMIN_JOBS_LIST = '/admin/jobs/list'
    static ROUTE_ADMIN_JOB_EDIT = (jobId) => `/admin/jobs/${jobId}/edit`
    // Admin Manage Routes
    static ROUTE_ADMIN_MANAGE_LIST_EMPLOYERS = '/admin/manage/employer/list'
    static ROUTE_ADMIN_MANAGE_ADD_EMPLOYER = '/admin/manage/employer/add'
    static ROUTE_ADMIN_MANAGE_LIST_JOB_SEEKERS = '/admin/manage/job-seeker/list'
    static ROUTE_ADMIN_MANAGE_ADD_JOB_SEEKER = '/admin/manage/job-seeker/add'
    static ROUTE_ADMIN_MANAGE_ADD_POOL_USERS = '/admin/manage/pool-users/add'
    static ROUTE_ADMIN_MANAGE_JOB_SEEKER_PROFILE = (jobSeekerId) => `/admin/manage/job-seeker/${jobSeekerId}/profile`
    static ROUTE_ADMIN_MANAGE_JOB_SEEKER_APPLICATIONS = (jobSeekerId) =>
        `/admin/manage/job-seeker/${jobSeekerId}/applications`
    static ROUTE_ADMIN_MANAGE_EMPLOYER_MEMBER_LIST = (employerId) => `/admin/manage/employer/member/${employerId}/list`
    static ROUTE_ADMIN_MANAGE_ADD_EMPLOYER_MEMBER = (employerId) => `/admin/manage/employer/member/${employerId}/add`
    static ROUTE_ADMIN_MANAGE_LIST_HR = '/admin/manage/admin/hr/list'
    static ROUTE_ADMIN_MANAGE_ADD_HR = '/admin/manage/admin/hr/add'
    // Admin Assignment Routes
    static ROUTE_ADMIN_ASSIGN_JOB_CANDIDATE = '/admin/assignment/candidate/job'
    // Admin Single Routes
    static ROUTE_ADMIN_INTERVIEWS = '/admin/interviews'
    static ROUTE_ADMIN_CHANGE_PWD = '/admin/change-password'
    static ROUTE_ADMIN_EMAIL_LOG = ({ id }) => `/admin/email/logs/${id}`
    // Admin Tag Routes
    static ROUTE_ADMIN_TAGS = '/admin/tags'
    static ROUTE_ADMIN_MANAGE_LIST_POOL_USERS = '/admin/manage/pool-users/list'
    static ROUTE_ADMIN_MANAGE_LIST_CAMPUS_USERS = '/admin/manage/campus-users/list'
    static ROUTE_ADMIN_TAG_JOB_SEEKERS = ({ id }) => `/admin/tags/${id}/job-seekers`

    static ROUTE_EMPLOYER_VIEW_JOB_SEEKER_PROFILE = (jobSeekerId) =>
        `/employer/dashboard/job-seeker/${jobSeekerId}/profile`

    static ROUTE_VENDOR_JOB_SEEKER_JOB_ASSIGNMENT = (jobSeekerId) => `/vendor/assignment/job-seeker/${jobSeekerId}/job`
    static ROUTE_ADMIN_ADD_COMMUNITY_POSTS = '/admin/community-post/add'
    static ROUTE_ADMIN_COMMUNITY_POSTS_EDIT = (postId) => `/admin/community-post/${postId}/edit`
    static ROUTE_ADMIN_COMMUNITY_POSTS_LIST = '/admin/community-post/list'
    static ROUTE_ADMIN_COMMUNITY_POST_DETAILS = (postId) => `/admin/community-post/${postId}/details`

    static ROUTE_ADMIN_ADD_ADVERTISEMENT = '/admin/advertisements/add'
    static ROUTE_ADMIN_ADVERTISEMENT_EDIT = (adsId) => `/admin/advertisements/${adsId}/edit`
    static ROUTE_ADMIN_ADVERTISEMENT_LIST = '/admin/advertisements/list'
    static ROUTE_ADMIN_CHAT_MESSAGE = ({ chatId }) => `/admin/community-chats/${chatId}/messages`

    /** Server (API) Route Constants */
    static API_ROUTE_LOGIN = '/auth/login'
    static API_ROUTE_LOGOUT = '/auth/logout'
    static API_ROUTE_REFRESH_TOKEN = '/auth/refresh-token'
    static API_FORGOT_PASSWORD_REQUEST = '/auth/forgot/password'
    static API_FORGOT_PWD_OTP = '/auth/forgot/password/verify'
    static API_FORGOT_PWD_CHANGE = '/auth/forgot/password/update'
    static API_ROUTE_REGISTER_EMPLOYER = '/auth/register/employer'
    static API_ROUTE_REGISTER_JOB_SEEKER = '/auth/register/jobseeker'
    static API_ROUTE_DIRECT_REGISTER = '/auth/register/user'
    static API_ROUTE_REGISTER_VERIFY_OTP = '/auth/register/verify/otp'
    static API_ROUTE_VALIDATE_ADP_TOKEN = '/auth/validate/idp/token'
    static API_ROUTE_GET_CITIES = '/common/search/city'
    static API_ROUTE_GET_EMPLOYERS = '/admin/search/employer'
    static API_ROUTE_GET_EMPLOYER_MEMBERS = (employerId) => `/admin/employer/${employerId}/members`
    static API_ROUTE_ADD_JOB_ADMIN = '/admin/add/job'
    static API_ROUTE_ADD_JOB_EMPLOYER = '/employer/add/job'
    static API_ROUTE_LIST_JOB_ADMIN = '/admin/view/jobs'
    static API_ROUTE_LIST_JOB_EMPLOYER = '/employer/view/jobs'
    static API_ROUTE_VIEW_JOB_ADMIN = (jobId) => `/admin/get/job/${jobId}`
    static API_ROUTE_VIEW_JOB_EMPLOYER = (jobId) => `/employer/get/job/${jobId}`
    static API_ROUTE_EDIT_JOB_ADMIN = (jobId) => `/admin/update/job/${jobId}`
    static API_ROUTE_EDIT_JOB_EMPLOYER = (jobId) => `/employer/update/job/${jobId}`
    static API_ROUTE_EDIT_JOB_FLAG_ADMIN = (jobId) => `/admin/update/job/flag/${jobId}`
    static API_ROUTE_EDIT_JOB_FLAG_EMPLOYER = (jobId) => `/employer/update/job/flag/${jobId}`
    static API_ROUTE_ADD_EMPLOYER_BY_ADMIN = '/admin/add/employer'
    static API_ROUTE_ADD_JOB_SEEKER_BY_ADMIN = '/admin/add/jobseeker'
    static API_ROUTE_ADD_SUB_HR_BY_ADMIN = '/admin/add/subHr'
    static API_ROUTE_GET_LANDING_JOBS = '/jobs/new'
    static API_ROUTE_GET_FEATURED_JOBS = '/jobs/featured'
    static API_ROUTE_GET_PUBLIC_JOB_BY_ID = (jobId) => `/jobs/${jobId}`
    static API_ROUTE_UPLOAD_RESUME = '/jobseeker/upload/resume'
    static API_ROUTE_UPLOAD_RESUME_ADMIN = (jobSeekerId) => `/admin/update/jobseeker/resume/${jobSeekerId}`
    static API_ROUTE_APPLY_JOB = (jobId) => `/jobseeker/apply/job/${jobId}`
    static API_ROUTE_VENDOR_APPLY_JOB = (jobId) => `/jobseeker/vendor/apply/job/${jobId}`
    static API_ROUTE_APPLY_JOB_ADMIN = (jobId, jobSeekerId) => `/admin/apply/job/${jobId}/${jobSeekerId}`
    static API_ROUTE_GET_RESUMES = '/jobseeker/view/resume'
    static API_ROUTE_GET_RESUMES_ADMIN = (jobSeekerId) => `/admin/view/${jobSeekerId}/resume`
    static API_ROUTE_ADMIN_GET_JOB_APPLICANTS = (jobId) => `/admin/job/${jobId}/applicants`
    static API_ROUTE_ADMIN_GET_JOB_SEEKER_POOL = (jobId) => `/admin/application/${jobId}/jobseeker/search/pool`
    static API_ROUTE_EMPLOYER_GET_JOB_APPLICANTS = (jobId) => `/employer/job/${jobId}/applicants`
    static API_ROUTE_ADMIN_UPDATE_JOB_STATUS = (applicationId, applicantId) =>
        `/admin/job/applications/${applicationId}/jobseeker/${applicantId}`
    static API_ROUTE_EMPLOYER_UPDATE_JOB_STATUS = (applicationId, applicantId) =>
        `/employer/job/applications/${applicationId}/jobseeker/${applicantId}`
    static API_ROUTE_EMPLOYER_ADD_PANELIST = '/employer/panelist'
    static API_ROUTE_EMPLOYER_ADD_PANELIST_ADMIN = (employerId) => `/admin/add/employer/member/${employerId}`
    static API_ROUTE_EMPLOYER_GET_PANELISTS = '/employer/panelists'
    static API_ROUTE_EMPLOYER_GET_JOB_SEEKER = '/employer/job-seekers'
    static API_ROUTE_EMPLOYER_GET_PANELISTS_ADMIN = '' // TODO: For admin
    static API_ROUTE_JOB_SEEKER_PROFILE = '/jobseeker/view/profile'
    static API_ROUTE_JOB_SEEKER_PROFILE_ADMIN = (jobSeekerId) => `/admin/view/${jobSeekerId}/profile`
    static API_ROUTE_JOB_SEEKER_PROFILE_EMPLOYER = (jobSeekerId) => `/employer/view/${jobSeekerId}/profile`
    static API_ROUTE_JOB_SEEKER_LATEST_RESUME = (jobSeekerId) => `/employer/view/${jobSeekerId}/latest/resume`
    static API_ROUTE_UPDATE_JOB_SEEKER_PROFILE = '/jobseeker/update/profile'
    static API_ROUTE_UPDATE_JOB_SEEKER_PROFILE_ADMIN = (jobSeekerId) => `/admin/update/jobseeker/profile/${jobSeekerId}`
    static API_ROUTE_SEND_TEST_LINK = (applicationId, applicantId) =>
        `/admin/job/applications/${applicationId}/jobseeker/${applicantId}/test/link`
    static API_ROUTE_UPDATE_USER_RATING = (jobSeekerId) => `/admin/rate/jobseeker/${jobSeekerId}`
    static API_ROUTE_VERIFY_JOB_SEEKER_MOB = (jobSeekerId) => `/admin/verify/jobseeker/${jobSeekerId}`
    static API_RESPOND_OFFER = (applicationId, response) => `/jobseeker/${applicationId}/offer/${response}`
    static API_RESPOND_INTERVIEW = (applicationId, response, interviewType, interviewId) =>
        `/employer/application/${applicationId}/interview/${interviewId}/${interviewType}/${response}`
    static API_VERIFY_USER_EMAIL = `/auth/register/verify/token`
    static API_ADMIN_GET_JOB_SEEKERS = '/admin/search/jobseeker'
    static API_ADMIN_GET_CAMPUS_JOB_SEEKERS = '/admin/search/campus/jobseeker'
    static API_ADMIN_GET_SUB_HRS = '/admin/sub/hr'
    static API_ADMIN_UPDATE_SUB_HR_STATUS = ({ id }) => `/admin/subHRStatus/${id}`
    static API_ADMIN_UPDATE_EMPLOYER_ACCESS_TO_JOBSEEKER_STATUS = ({ employerId }) =>
        `/admin/employer/jobSeekerAccess/${employerId}`
    static API_ADMIN_MAP_SUB_HR_JS = ({ jobSeekerId, subHRId }) => `/admin/job-seeker/${jobSeekerId}/sub/hr/${subHRId}`
    static API_GET_INTERVIEWS = '/admin/list/interviewees'
    static API_GET_MESSAGES = (interviewId) => `/common/view/messages/${interviewId}`
    static API_SEND_MESSAGES = (interviewId) => `/common/add/messages/${interviewId}`
    static API_GET_JOB_SEEKER_DOCS = (jobSeekerUuId) => `/jobseeker/view/document/${jobSeekerUuId}`
    static API_GET_JOB_SEEKER_DOCS_OTHERS = (applicationId, jobSeekerUuId, userType) =>
        `/common//view/jobseeker/doc/${applicationId}/${jobSeekerUuId}/${userType}`
    static API_UPLOAD_JOB_SEEKER_DOC = (jobSeekerUuId) => `/jobseeker/upload/document/${jobSeekerUuId}`
    static API_EMPLOYER_USER_DOC_ACCESS = (applicationId) => `/admin/update/applications/userdocaccess/${applicationId}`
    static API_ADMIN_JS_BEST_FIT = (applicationId, fitValue) =>
        `/admin/application/${applicationId}/is-best-fit/${fitValue}`
    static API_UPDATE_USER_AUTH_PWD = '/auth/change/password'
    static API_GET_JOB_SEEKER_APPLICATIONS = (jobSeekerId) => `/admin/jobseeker/application/${jobSeekerId}`
    static API_GET_MY_APPLICATIONS = '/jobseeker/applications'
    static API_MODIFY_PANELIST_RESULT_ACCESS = (panelistId, access) =>
        `/employer/panel/${panelistId}/interview/result/access/${access}`
    static API_REMOVE_INTERVIEW_ADMIN = (interviewId) => `/admin/delete/interview/${interviewId}`
    static API_ADMIN_GET_TAGS = '/admin/tags'
    static API_ADMIN_CREATE_TAG = '/admin/tags'
    static API_ADMIN_CANDIDATE_TAG_MAPPING = ({ tagId, jobSeekerId, mapValue }) =>
        `/admin/tags/${tagId}/job-seeker/${jobSeekerId}/${mapValue}`
    static API_ADMIN_DELETE_TAG = ({ id }) => `/admin/tags/${id}`
    static API_ADMIN_TAG_JOB_SEEKERS = ({ tagId }) => `admin/tags/${tagId}/job-seekers`
    static API_PANELIST_UPDATE_FEEDBACK = (interviewId) => `/employer/interview/${interviewId}`
    static API_CANCEL_INTERVIEW = ({ interviewId }) => `/employer/cancel/interview/${interviewId}`
    static API_GET_EMPLOYER_SCHEDULE = '/employer/interview/schedule'
    static API_ROUTE_GET_ADMIN_DASHBOARD_STATS = '/admin/dashboard'
    static API_EMPLOYER_DASHBOARD_STATISTICS = '/employer/jobs/statistics'
    static API_ADMIN_GET_APPLICANTS_BY_STATUS = '/admin/applicants'
    static API_ADMIN_GET_APPLICATION_TIMELINE = ({ id }) => `/admin/${id}/timeline`
    static API_ADMIN_GET_NON_VERIFIED_JOB_SEEKERS = '/admin/job-seeker/non-verified'
    static API_ADMIN_GET_EMAIL_LOGS = '/common/email/logs'
    static API_ADMIN_GET_EMAIL_LOG = ({ id }) => `/common/email/logs/${id}`
    static API_ADMIN_GET_SIDEBAR_DATA = '/admin/sidebar'
    static API_JOB_SEEKER_SERVE_NOTICE_PERIOD = (applicationId) =>
        `/admin/applications/serveNoticePeriod/${applicationId}`
    static API_ADMIN_CREATE_ADMIN = '/admin'
    static API_ADMIN_EDIT_ADMIN = ({ adminId }) => `/admin/${adminId}`
    static API_ADMIN_GET_ALL_SIDEBAR_URLS = '/admin/sidebar/all'
    static API_ADMIN_GET_ALL_ADMINS = '/admin'
    static API_ADMIN_GET_ADMIN = ({ adminId }) => `/admin/${adminId}/get`

    //V2 API list
    static API_ADMIN_GET_BLOGS = '/admin/search/blogs'
    static API_ADMIN_GET_BLOG = ({ blogId }) => `/admin/blog/${blogId}/get`
    static API_ADMIN_ADD_BLOG = '/admin/add/blog'
    static API_EMPLOYER_UPDATE_BLOG = ({ blogId }) => `/admin/update/blog/${blogId}`

    static API_ADMIN_GET_SITEMAPS = '/admin/search/sitemaps'
    static API_ADMIN_GET_SITEMAP = ({ sitemapId }) => `/admin/sitemap/${sitemapId}/get`
    static API_ADMIN_ADD_SITEMAP = '/admin/add/sitemap'
    static API_EMPLOYER_UPDATE_SITEMAP = ({ sitemapId }) => `/admin/update/sitemap/${sitemapId}`
    static API_ADMIN_GET_ALL_SITEMAPS = '/admin/sitemaps'

    static API_ADMIN_GET_FOOTERS = '/admin/search/footers'
    static API_ADMIN_GET_FOOTER = ({ footerId }) => `/admin/footer/${footerId}/get`
    static API_ADMIN_ADD_FOOTER = '/admin/add/footer'
    static API_EMPLOYER_UPDATE_FOOTER = ({ footerId }) => `/admin/update/footer/${footerId}`

    static API_ADMIN_UPDATE_COMPANY_HIGHLIGHTS = ({ employerId }) => `/admin/update/company/highlights/${employerId}`

    static API_EMPLOYER_CREATE_VENDOR = '/employer/add/vendor'
    static API_EMPLOYER_EDIT_VENDOR = ({ vendorId }) => `/employer/update/vendor/${vendorId}`
    static API_ADMIN_GET_VENDOR = ({ vendorId }) => `/employer/vendor/${vendorId}/get`
    static API_ROUTE_VENDOR_GET_JOB_SEEKER = '/employer/vendor/job-seekers'

    static API_ROUTE_VENDOR_EMPLOYER_JOBS = '/employer/vendor/jobs'
    static API_ADMIN_ADD_SUBSCRIPTION_STATUS = ({ employerId }) => `/admin/add/company/subscription/${employerId}`

    static ROUTE_ADMIN_MANAGE_SUBHR_JOBSEEKER_LIST = (subHrId) => `/admin/manage/hr/job-seeker/${subHrId}/list`
    static ROUTE_ADMIN_MANAGE_SUBHR_POOL_USERS_LIST = (subHrId) => `/admin/manage/hr/pool-users/${subHrId}/list`
    static ROUTE_ADMIN_ADD_BLOG = '/admin/blog/add'
    static ROUTE_ADMIN_BLOG_LIST = '/admin/blog/list'
    static ROUTE_ADMIN_BLOG_EDIT = (blogId) => `/admin/blog/${blogId}/edit`

    static ROUTE_ADMIN_ADD_SITEMAP = '/admin/sitemap/add'
    static ROUTE_ADMIN_SITEMAP_LIST = '/admin/sitemap/list'
    static ROUTE_ADMIN_SITEMAP_EDIT = (sitemapId) => `/admin/sitemap/${sitemapId}/edit`

    static ROUTE_ADMIN_ADD_FOOTER = '/admin/footer/add'
    static ROUTE_ADMIN_FOOTER_LIST = '/admin/footer/list'
    static ROUTE_ADMIN_FOOTER_EDIT = (footerId) => `/admin/footer/${footerId}/edit`

    static ROUTE_ADMIN_TERMS_CONDITIONS = '/admin/terms-conditions'

    static API_OTP_SEND_POOL_USER = `/auth/register/pool/user/send/otp`
    static API_VERIFY_OTP_POOL_USER = '/auth/register/pool/user/verify/otp'
    static API_REGISTER_POOL_USER = '/auth/register/pool/user'
    static ROUTE_POOL_USER_REGISTRATION = '/registration'

    static API_ADMIN_GET_COMMUNITY_POSTS = '/admin/search/community-posts'
    static API_ADMIN_ADD_COMMUNITY_POST = '/admin/community-post/add'
    static API_ADMIN_COMMUNITY_POST_EDIT = (postId) => `/admin/community-post/${postId}/edit`
    static API_ADMIN_GET_COMMUNITY_POST = ({ postId }) => `/admin/community-post/${postId}/get`
    static API_ADMIN_UPDATE_COMMUNITY_POST = ({ postId }) => `/admin/update/community-post/${postId}`
    static API_ADMIN_CHANGE_COMMUNITY_POST_STATUS = (postId) => `/admin/update/status/community-post/${postId}`
    static API_EMPLOYER_UPDATE_COMMUNITY_POST = ({ postId }) => `/admin/update/community-post/${postId}`

    static API_ADMIN_GET_ADVERTISEMENTS = '/admin/search/advertisements'
    static API_ADMIN_ADD_ADVERTISEMENT = '/admin/advertisement/add'
    static API_ADMIN_ADVERTISEMENT_EDIT = (adsId) => `/admin/advertisement/${adsId}/edit`
    static API_ADMIN_GET_ADVERTISEMENT = ({ adsId }) => `/admin/advertisement/${adsId}/get`
    static API_ADMIN_UPDATE_ADVERTISEMENT = ({ adsId }) => `/admin/update/advertisement/${adsId}`
    static API_ADMIN_ADD_ADVERTISEMENT_MEDIA = '/admin/media/upload'

    static API_ADMIN_DELETE_POST_COMMENT = ({ id }) => `/admin/community-post/comment/${id}`

    static API_ADMIN_GET_COMMUNITY_CHATS = '/admin/search/community-chats'
    static API_ADMIN_GET_CHAT_MESSAGES = ({ chatId }) => `/admin/community-chats/${chatId}/messages`

    /**
     * Routes to change from CMS to API for mail notification
     */
    static API_BUY_SERVICE_CONTACT_FORM_ENQUIRY = '/common/mail/buy-service-enquiry'
    static API_SUBMIT_CONTACT_ENQUIRY = '/common/mail/contact-us-enquiry'
    static API_SUBMIT_CRM_MAIL_FORM = '/admin/mail/crm'
    static API_SUBMIT_SUB_HR_FORM = '/admin/mail/sub-hr/contract'

    /** CMS Routes */
    static CMS_GET_SITEMAP = '/sitemaps/find'

    static API_ROUTE_EMPLOYER_GET_VENDOR = '/employer/vendors'

    static API_VENDOR_CREATE_JOB_SEEKER = '/employer/vendor/add/job-seeker'
    static API_VENDOR_GET_JOB_SEEKERS = '/employer/vendor/job-seekers'

    static CMS_GET_SITEMAP_SLUG_DATA = ({ slug }) => `/sitemaps/details/${slug}`

    static API_ADMIN_UPDATE_JOB_SEEKER_IS_POOL_STATUS = ({ jobSeekerId }) =>
        `/admin/update/job-seeker/pool/${jobSeekerId}`

    static API_ADMIN_UPDATE_EMPLOYER_STATUS = ({ employerId }) => `/admin/employer/update/status/${employerId}`
    static API_ADMIN_UPDATE_EMPLOYER_CV_COMMUNITY_RESUME_ACCESS = ({ employerId }) =>
        `/admin/employer/update/community/cvaccess/${employerId}`
    static API_ADMIN_UPDATE_JOB_SEEKER_STATUS = ({ jobSeekerId }) => `/admin/update/job-seeker/status/${jobSeekerId}`

    /** Type constants */
    static TYPE_USER_JOB_SEEKER = 'jobseeker'
    static TYPE_USER_EMPLOYER = 'employer'
    static TYPE_USER_EMPLOYER_COMPANY = 'company'
    static TYPE_USER_EMPLOYER_HR = 'hr'
    static TYPE_USER_EMPLOYER_INTERVIEWER = 'interviewer'
    static TYPE_USER_ADMIN = 'admin'
    static TYPE_USER_ADMIN_MAIN = 'admin'
    static TYPE_USER_ADMIN_SUB_HR = 'subhr'
    static TYPE_VERIFY_OTP_EMAIL = 'otp_email'
    static TYPE_VERIFY_OTP_REGISTER = 'otp_register'
    static TYPE_RESPONSE_YES_NO = 'Yes/No'
    static TYPE_RESPONSE_NUMERIC = 'Numeric'
    static TYPE_JOB_SAMPLE = 'sample'
    static TYPE_ADMIN_ROUTE_ALLOWED_ALL = 'ALL'
    static WHATSAPP_URL_CLIENT_SUPPORT = 'https://wa.me/'

    static VENDOR_TYPE_VENDOR = 'VENDOR'
    static VENDOR_TYPE_HR = 'HR'
    static TYPE_ADD = 'add'
    static TYPE_EDIT = 'edit'

    static TYPE_COMPANY_COMPANY = 1
    static TYPE_COMPANY_HIRING_AGENCY = 2

    static ROUTE_CLIENT_EMPLOYER_VENDOR_EDIT = (vendorId) => `/employer/dashboard/vendor/${vendorId}/edit`

    // Job Application Statuses
    static STATUS_JOB_APP_APPLIED = 'applied'
    static STATUS_JOB_APP_ALLOTED = 'allotted'
    static STATUS_JOB_APP_SHORTLISTED = 'shortlisted'
    static STATUS_JOB_APP_INTERVIEW = 'interview'
    static STATUS_JOB_APP_HR = 'hr'
    static STATUS_JOB_APP_OFFERED = 'offered'
    static STATUS_JOB_APP_JOINED = 'joined'
    static STATUS_JOB_APP_REJECT = 'reject'
    static STATUS_JOB_APP_HOLD = 'hold'
    static STATUS_JOB_APP_NO = 'notApplied'

    // Job Interview statuses
    static STATUS_JOB_INTERVIEW_SCHEDULED = 'scheduled'
    static STATUS_JOB_INTERVIEW_RESCHEDULED = 'rescheduled'

    /** Toast types */
    static TYPE_TOAST_SUCCESS = 'success'
    static TYPE_TOAST_DANGER = 'error'
    static TYPE_TOAST_WARNING = 'warning'
    static TYPE_TOAST_DEFAULT = 'default'
    static TYPE_TOAST_INFO = 'info'

    /** Result Types */
    static TYPE_RESULT_PENDING = 'PENDING'

    /** Toast Positions */
    static POSITION_TOAST_TOP_LEFT = 'top-left'
    static POSITION_TOAST_TOP_RIGHT = 'top-right'
    static POSITION_TOAST_TOP_CENTER = 'top-center'
    static POSITION_TOAST_BOTTOM_LEFT = 'bottom-left'
    static POSITION_TOAST_BOTTOM_RIGHT = 'bottom-right'
    static POSITION_TOAST_BOTTOM_CENTER = 'bottom-center'

    /** Response status Constants */
    static STATUS_UNAUTHORIZED = 401
    static STATUS_NOT_FOUND = 404
    static STATUS_UNPROCESSABLE_ENTITY = 422
    static STATUS_BAD_REQUEST = 400
    static STATUS_CONFLICT = 409
    static STATUS_SUCCESS = 200

    /** Local Storage key constants */
    static KEY_LS_ACCESS_TOKEN = 'accessToken'
    static KEY_LS_REFRESH_TOKEN = 'refreshToken'
    static KEY_LS_EXPIRES_IN = 'expiresIn'
    static KEY_LS_USER = 'user'

    /** Anon Type constants */
    static TYPE_ANON_EDIT = 'edit'
    static TYPE_ANON_ADD = 'add'
    static TYPE_ANON_DELETE = 'isDeleted'
    static TYPE_ANON_APPROVE = 'isAdminApproved'
    static TYPE_ANON_RENEW = 'renew'
    static TYPE_ANON_SAMPLE_JOBS_ARR = ['sample1', 'sample2']
    static TYPE_INTERVIEW_HR = 'hr'
    static TYPE_INTERVIEW_TECHNICAL = 'technical'
    static TYPE_UPDATE_BASIC_DETAILS = 'basic'
    static TYPE_UPDATE_CV_HEADLINE = 'cv_headline'
    static TYPE_UPDATE_MAJOR_SKILLS = 'major_skills'
    static TYPE_UPDATE_MINOR_SKILLS = 'minor_skills'
    static TYPE_ADD_WORK_HISTORY = 'work_history'
    static TYPE_EDIT_WORK_HISTORY = 'edit_work_history'
    static TYPE_ADD_PROJECTS = 'add_projects'
    static TYPE_EDIT_PROJECTS = 'edit_projects'
    static TYPE_ADD_PROJECT_LINKS = 'add_project_links'
    static TYPE_EDIT_PROJECT_LINKS = 'edit_project_links'
    static TYPE_ADD_EDUCATION = 'add_education'
    static TYPE_EDIT_EDUCATION = 'edit_education'
    static TYPE_EDIT_PERSONAL_DETAILS = 'edit_personal_details'
    static TYPE_ADD_CERTIFICATION = 'add_certification'
    static TYPE_EDIT_CERTIFICATION = 'edit_certification'
    static TYPE_ADD_SOCIAL_LINK = 'add_social_link'
    static TYPE_EDIT_SOCIAL_LINK = 'edit_social_link'
    static TYPE_OFFER_RESPONSE_ACCEPT = 'accept'
    static TYPE_OFFER_RESPONSE_REJECT = 'reject'
    static TYPE_RESULT_PASSED = 'PASSED'
    static TYPE_RESULT_FAILED = 'FAILED'
    static TYPE_RESULT_ACCEPTED = 'ACCEPTED'
    static TYPE_RESULT_REJECTED = 'REJECTED'

    static QUALIFICATIONS = [
        { value: '', name: 'Select Highest Qualification' },
        { value: 'HS', name: 'HS' },
        { value: 'Diploma', name: 'Diploma' },
        { value: 'Graduation', name: 'Graduation' },
        { value: 'Post Graduation', name: 'Post Graduation' },
        { value: 'B. Tech', name: 'B.TECH' },
        { value: 'M. Tech', name: 'M.TECH' },
        { value: 'PHD', name: 'PHD' },
        { value: 'BBA', name: 'BBA' },
        { value: 'MBA', name: 'MBA' }
    ]

    /** Lists */
    static LIST_DEFAULT_TECH_SKILLS = [
        'Python',
        'Java',
        'C#',
        'JavaScript',
        'C++',
        'Ruby',
        'Swift',
        'Kotlin',
        'PHP',
        'TypeScript',
        'Go',
        'Rust',
        'Perl',
        'R',
        'Dart',
        'MATLAB',
        'Lua',
        'Groovy',
        'Scala',
        'Haskell',
        'Angular',
        'React',
        'Vue.js',
        'Node.js',
        'Express.js',
        'Django',
        'Flask',
        'ASP.NET',
        'Ruby on Rails',
        'WebAssembly',
        'Ember.js',
        'Backbone.js',
        'Knockout.js',
        'Polymer',
        'Android Development',
        'iOS Development (Swift/Objective-C)',
        'Xamarin',
        'React Native',
        'Flutter',
        'Ionic',
        'PhoneGap',
        'SQL',
        'MySQL',
        'PostgreSQL',
        'MongoDB',
        'Oracle',
        'SQLite',
        'Redis',
        'Cassandra',
        'Git',
        'GitHub',
        'GitLab',
        'Bitbucket',
        'Mercurial',
        'Subversion (SVN)',
        'DevOps',
        'Docker',
        'Kubernetes',
        'Jenkins',
        'Travis CI',
        'CircleCI',
        'Ansible',
        'Puppet',
        'Chef',
        'Terraform',
        'Amazon Web Services (AWS)',
        'Microsoft Azure',
        'Google Cloud Platform (GCP)',
        'IBM Cloud',
        'Heroku',
        'GraphQL',
        'WebSockets',
        'Serverless Architecture',
        'Microservices Architecture',
        'Flask RESTful',
        'Babel',
        'Sass/SCSS',
        'Responsive Design',
        'CSS Preprocessors (Less, Stylus)',
        'CSS Frameworks (Bootstrap, Foundation)',
        'Material Design',
        'Integration Testing',
        'Regression Testing',
        'Load Testing',
        'Performance Testing',
        'Selenium',
        'JUnit',
        'TestNG',
        'PyTest',
        'Agile Methodology',
        'Scrum Framework',
        'Kanban',
        'Lean Software Development',
        'Extreme Programming (XP)',
        'CI/ CD Pipeline',
        'OWASP Security Guidelines',
        'Secure Coding Practices',
        'Penetration Testing',
        'Security Auditing',
        'Static Code Analysis',
        'Code Linting',
        'Code Smells Detection',
        'Load Balancing',
        'Caching Strategies',
        'Compression Techniques',
        'Networking',
        'TCP/IP',
        'HTTP/HTTPS',
        'DNS',
        'Network Protocols',
        'Machine Learning',
        'TensorFlow',
        'PyTorch',
        'Scikit-Learn',
        'Keras',
        'Natural Language Processing (NLP)',
        'Computer Vision',
        'Reinforcement Learning',
        'Pandas',
        'NumPy',
        'Data Visualization (Matplotlib, Seaborn, Plotly)',
        'Data Analysis',
        'Data Cleaning',
        'Statistical Analysis',
        'Cybersecurity',
        'Ethical Hacking',
        'Network Security',
        'Encryption',
        'Intrusion Detection Systems (IDS)',
        'UI/UX Design',
        'User-Centered Design',
        'Interaction Design',
        'Wireframing',
        'Sketch',
        'Figma',
        'Adobe XD',
        'Project Management',
        'Waterfall Methodology',
        'Critical Path Method (CPM)',
        'Gantt Charts',
        'Risk Management',
        'REST API Design',
        'API Documentation (Swagger, Postman)',
        'API Security',
        'Clojure',
        'Functional Design Patterns',
        'Service Discovery',
        'API Gateway',
        'Event-Driven Architecture',
        'Domain-Driven Design (DDD)',
        'ROS (Robot Operating System)',
        'Robot Control Systems',
        'Computer Vision for Robotics',
        'Motion Planning',
        'Embedded Systems',
        'Real-Time Operating Systems (RTOS)',
        'VHDL',
        'Verilog',
        'IoT (Internet of Things)',
        'IoT Protocols (MQTT, CoAP)',
        'Sensor Integration',
        'Edge Computing',
        'Blockchain',
        'Smart Contracts (Solidity)',
        'Decentralized Applications (DApps)',
        'Consensus Algorithms',
        'Quantum Algorithms',
        'Quantum Programming (Qiskit, Cirq)',
        'Unity3D',
        'Unreal Engine',
        'Augmented Reality SDKs (ARKit, ARCore)',
        'Virtual Reality Content Creation',
        'Localization (l10n)',
        'Translation Management',
        'Big Data',
        'Hadoop',
        'Spark',
        'Hive',
        'Pig',
        'HBase',
        'Kafka',
        'NoSQL',
        'Couchbase',
        'Neo4j',
        'Amazon DynamoDB',
        'Selenium WebDriver',
        'TestCafe',
        'Watir',
        'Geb',
        'Jekyll',
        'Hugo',
        'Gatsby',
        'Markdown',
        'reStructuredText',
        'Doxygen',
        'Sphinx',
        'Postman',
        'Insomnia',
        'SoapUI',
        'Teamcenter',
        'PLM software',
        'Tigergraph'
    ]

    static TYPE_COLORS_CLASS_ARR = ['primary', 'danger', 'warning', 'success', 'info', 'dark']

    /** Default path vars */
    static DEFAULT_PATH_JOB_SEEKER_AVATAR = '/assets/img/user/default-avatar.png'
    static DEFAULT_PATH_EMPLOYER_AVATAR = '/assets/img/company/default-avatar.png'
    static DEFAULT_PATH_NO_IMAGE = '/assets/img/no_image.jpg'

    static DEFAULT_BLOG_IMAGE = 'https://revenuearchitects.com/wp-content/uploads/2017/02/Blog_pic-1030x584.png'
    /** Regex's */
    static REGEX_VALID_EMAIL =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    static REGEX_VALID_PASSWORD = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
    static REGEX_VALID_MOBILE_NUMBER = /^\d{10}$/
    static REGEX_VALID_DATE =
        /^(?:(?:31(-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(-)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    static REGEX_ADMIN_ROUTE = /^\/admin/

    /** Error Messages */
    static MSG_UNEXPECTED_ERROR = 'Unexpected error occurred! Please try again later.'
    static MSG_INVALID_EMAIL = 'Please enter a valid email address!'
    static MSG_INVALID_MOBILE_NUMBER = 'Please enter a valid phone number!'
    static MSG_INVALID_PASSWORD_LOGIN = 'Please enter your password'
    static MSG_INVALID_PASSWORD =
        "Your password doesn't meet minimum security requirements! It should be between 6-16 characters long and contain at-least a number and a special character."
    static MSG_EMPTY_COMPANY_NAME = 'Please enter your Company name!'
    static MSG_EMPTY_COMPANY_TYPE = 'Please select your Company type!'
    static MSG_INVALID_OTP = 'Please enter correct OTP!'
    static MSG_EMPTY_FIRST_NAME = 'Please enter first name!'
    static MSG_EMPTY_LAST_NAME = 'Please enter last name!'
    static MSG_EMPTY_COMPANY_DESIGNATION = 'Please enter the company Designation!'
    static MSG_EMPTY_EMPLOYER = 'Please select an Employer!'
    static MSG_EMPTY_POSITION_NAME = 'Please enter a valid position name!'
    static MSG_INVALID_EXP = 'The combination of Min & Max Experience is incorrect!'
    static MSG_INVALID_SALARY = 'The combination of Min & Max Salary is incorrect!'
    static MSG_LESS_SALARY = "The annual salary can't be zero!"
    static MSG_EMPTY_QUALIFICATION = 'Please select highest qualification!'
    static MSG_EMPTY_LOCATION = 'Please select correct location!'
    static MSG_EMPTY_TECH_SKILLS = 'Please add some technical skills!'
    static MSG_EMPTY_JOB_DESC = "The job description can't be empty!"
    static MSG_EMPTY_RESUME = 'Please select your resume!'
    static MSG_EMPTY_DOC_FILE = 'Please select a file to be uploaded!'
    static MSG_EMPTY_RESUME_UPLOAD = 'Please select or upload your resume!'
    static MSG_EMPTY_RESUME_TITLE = 'Please enter your resume title!'
    static MSG_EMPTY_DOCUMENT_CATEGORY = 'Please select a category!'
    static MSG_EMPTY_PANELIST_TYPE = 'Please select the panelist type!'
    static MSG_INVALID_PANELIST_FIRST_NAME = 'Please enter panelist first name!'
    static MSG_INVALID_PANELIST_LAST_NAME = 'Please enter panelist last name!'
    static MSG_INVALID_PANELIST_EMAIL = 'Please enter panelist email correctly!'
    static MSG_INVALID_PANELIST_PH_NUMBER = 'Please enter panelist mobile number correctly!'
    static MSG_EMPTY_PANELIST = 'Please select a panelist to continue!'
    static MSG_EMPTY_INTERVIEW_DATE = 'Please select date and time of the interview correctly!'
    static MSG_EMPTY_OFFER_LETTER = 'Please select an offer letter!'
    static MSG_EMPTY_REJECT_REASON = 'Please select a reason for rejection!'
    static MSG_EMPTY_COMPANY_DESIGNATION = 'Please Enter your Designation!'
    static MSG_EMPTY_PROJECT_TITLE = 'Please Enter your Project Title!'
    static MSG_EMPTY_WORKING_FROM_YEAR = 'Please Select your working from year!'
    static MSG_EMPTY_WORKING_FROM_MONTH = 'Please Select your working from month!'
    static MSG_EMPTY_WORKING_TO_YEAR = 'Please Select your working till year!'
    static MSG_EMPTY_WORKING_TO_MONTH = 'Please Select your working till month!'
    static MSG_EMPTY_RESPONSIBILITIES = 'Please Enter your responsibilities!'
    static MSG_EMPTY_PROJ_DETAILS = 'Please Enter your project details!'
    static MSG_EMPTY_PROJ_LINK = 'Please Enter your project URL!'
    static MSG_EMPTY_CTC_OFFERED = 'Please Enter the CTC to be offered correctly and a value greater than 0!'
    static MSG_EMPTY_JOINING_DATE = 'Please Enter the joining date!'
    static MSG_EMPTY_JOINING_LOCATION = 'Please Enter the joining location!'
    static MSG_REQUIRED__LOCATION = 'Please Enter the location!'
    static MSG_EMPTY_ADMIN_TEST_LINK = 'Please Enter message to be sent!'
    static MSG_EMPTY_ADMIN_TEST_SUBJECT = 'Please Enter Email subject!'
    static MSG_EMPTY_ADMIN_TEST_BODY = 'Please Enter Email Body!'
    static EMAIL_LIST_EMAIL = 'Please select a Job-seeker to send email!'
    static MSG_SEND_CRM_EMAIL_SUCCESS = 'A mail with details has been sent successfully to selected job-seekers!'
    static MSG_SEND_CRM_EMAIL_ERROR = 'There is some error occurred while sending email. Please try again!'
    static MSG_EMPTY_EDUCATION_TYPE = 'Please select your Education Type!'
    static MSG_EMPTY_COURSE = 'Please Enter your Course Name!'
    static MSG_EMPTY_SPECIALIZATION = 'Please Enter your specialization!'
    static MSG_EMPTY_UNIV_NAME = 'Please Enter your University Name!'
    static MSG_EMPTY_PASS_YEAR = 'Please select your Pass-out year!'
    static MSG_EMPTY_CERTIFICATION_NAME = 'Please Enter your certification name!'
    static MSG_EMPTY_SOCIAL_LINK = 'Please Enter you social link!'
    static MSG_EMPTY_SEARCH_PARAM = 'Please Enter your search parameters!'
    static MSG_EMPTY_MESSAGE = 'Please Enter a message to be sent!'
    static MSG_EMPTY_CURR_PWD = 'Please Enter your current password!'
    static MSG_EMPTY_NEW_PWD = 'Please Enter a new password!'
    static MSG_EMPTY_CONFIRM_PWD = 'Please confirm your new password!'
    static MSG_PWD_NO_MATCH = "Your new password and confirm password doesn't match!"
    static MSG_INVALID_RATING = 'Please select the rating correctly!'
    static MSG_FROM_DATE_BIGGER = 'The from date cannot be greater than to date!'
    static MSG_INVALID_OTHER_PANEL_EMAIL = 'Please enter valid email address/es for the other panelists!'
    static MSG_TAG_NAME_EMPTY = 'Please enter the list name!'
    static OPERATION_NOT_ALLOWED = 'You are not authorized to perform the requested operation!'
    static FEEDBACK_NULL = 'Feedback cannot be empty!'
    static CANCEL_INTERVIEW_REASON_NULL = 'Please enter the reason for cancellation!'
    static MINIMUM_TOTAL_HIRING = 'Minimum hiring for this job must be one!'
    static MSG_EMPTY_TOTAL_EXP = 'Please enter your total experience!'
    static MSG_EMPTY_NOTICE_PERIOD = 'Please enter your notice period!'
    static MSG_EMPTY_EXPECTED_CTC = 'Please enter your expected CTC!'
    static MSG_EMPTY_MAJOR_SKILLS = 'Please add technical skills!'
    static MSG_EMPTY_VERIFICATION_CODE = 'Please enter verification code!'
    static MSG_EMPTY_POOL_USER_FORM_DATA =
        'Please provide all the form details and verify your mobile number to proceed!'
    static MSG_OTP_VERIFIED_SUCCESS_UPLOAD_RESUME =
        'OTP is verified successfully. Please proceed to upload resume to complete the registration!'

    static MSG_TERMS_UPDATED_SUCCESS = 'Terms and conditions updated successfully !'

    static MSG_INVALID_NAME = 'Name cannot contain numbers or special characters!'

    static MSG_GREATER_THAN_ZERO_MAXIMUM_NOTICE_PERIOD = 'Maximum notice period must be grater than 0!'

    /** Positive messages */
    static MSG_FORGOT_PASSWORD_REQ_SENT =
        'If the email address you provided exists in our records, then you must have received a verification pin.'
    static MSG_PWD_UPDATE_SUCCESS_FP = 'Hurray! Your password has been updated. You can try to login now.'
    static MSG_ADD_JOB_SUCCESS_ADMIN = 'The Job has been added to the system'
    static MSG_EDIT_JOB_SUCCESS_ADMIN = 'The Job has been updated!'
    static MSG_JOB_FLAG_SUCCESS_ADMIN = 'The Job flag has been updated!'
    static MSG_JOB_FLAG_DELETE_SUCCESS = 'The Job has been deleted!'
    static MSG_EMPLOYER_ADD_SUCCESS_ADMIN =
        'The Employer has been added to the system. Please inform to change the password on first login for security reasons!'
    static MSG_JOB_SEEKER_ADD_SUCCESS_ADMIN =
        "The Job Seeker has been added to the system. We have sent a temporary password to the user's email address for first time login!"
    static MSG_SUB_HR_ADD_SUCCESS_ADMIN =
        "The Sub-HR has been added to the system. We have sent a temporary password to the user's email address for first time login!"

    static MSG_POOL_USERS_ADD_SUCCESS_ADMIN =
        "The Pool User has been added to the system. We have sent a temporary password to the user's email address for first time login!"

    static MSG_JOB_QUESTIONNAIRE_ANS_INVALID = 'Please fill all the details!'
    static MSG_JOB_APPLICATION_SENT =
        'Hurray! Your job application has been sent. Please wait for further communication from our side.'
    static MSG_CANDIDATE_SHORTLISTED = "The Candidate has been shortlisted! Let's setup an interview now."
    static MSG_PANELIST_ADDED = 'The panelist has been added to the system!'
    static MSG_INTERVIEW_SCHEDULED = 'The interview has been scheduled!'
    static MSG_OFFER_SENT_TO_CANDIDATE = 'Hurray! The offer has been sent to the candidate'
    static MSG_PROFILE_UPDATE_SUCCESS = 'Your profile has been updated!'
    static MSG_TEST_LINK_SENT = 'The email message has been sent to the job-seeker!'
    static MSG_THANK_YOU_RESPONSE = 'Your response has been recorded. Thank you!'
    static MSG_EMAIL_VERIFY_SUCCESS = 'Your email has been successfully verified! You can login and use your account!'
    static MSG_LINK_COPIED_CLIPBOARD = 'Link has been copied to clipboard!'
    static MSG_EMPLOYER_ACCESS_DOC_SUCCESS = "The Employer's job-seeker documents access has been toggled successfully!"
    static MSG_USER_VERIFIED = 'The job-seeker has been verified successfully!'
    static MSG_USER_BIT_FIT_TOGGLE = 'The candidate best fit flag has been toggled!'
    static MSG_TAG_DELETE_SUCCESS = 'The tag list has been deleted!'
    static UPDATE_FEEDBACK_SUCCESS = 'Feedback has been updated successfully!'
    static MSG_INTERVIEW_STATUS_CHANGED = 'The interview status has been changed successfully!'
    static MSG_SERVE_NOTICE_PERIOD_SUCCESS = 'Serving notice period status changed successfully!'
    static MSG_SUB_HR_STATUS_CHANGE_SUCCESS = 'SubHR status changed successfully!'
    static MSG_REG_POOL_USER_SUCCESS = 'Hurray! Your account has been created successfully!'
    static MSG_MOVE_TO_POOL_SUCCESS = 'Job-Seeker moved to pool successfully!'
    static MSG_MOVE_FROM_POOL_SUCCESS = 'Job-Seeker moved out from pool successfully!'
    static MSG_REGISTER_SUCCESS =
        'Registration Successful! We have sent a verification link to your provided email address.'
    static MSG_SUCCESS_UPDATE_HIGHLIGHTS = 'Company highlights updated successfully!'

    static MSG_SUCCESS_ADD_SUBSCRIPTION = 'Subscription status added successfully!'
    static MSG_SUCCESS_UPDATE_SUBSCRIPTION = 'Subscription status updated successfully!'

    static MSG_STATUS_SUCCESS = 'status changed successfully!'
    static MSG_STATUS_POST_APPROVED = 'Post is approved successfully !'
    static MSG_STATUS_POST_REJECTED = 'Post is rejected successfully !'
    static MSG_STATUS_POST_APPROVE_CONFIRM = 'Are you sure you want to approve this post !'
    static MSG_STATUS_POST_REJECT_CONFIRM = 'Are you sure you want to reject this post !'
    static MSG_CV_COMMUNITY_ACCESS_SUCCESS = 'Community CV access permission changed successfully!'

    /** General Message */
    static MSG_GEN_ALERT_UNASSIGN = 'Confirm Again!'
    static MSG_GEN_ALERT_UNASSIGN_DESC =
        'You are going to un-assign Job Seeker from Employer. Employer will have No access to this Prospect anymore.'
    static MSG_GEN_ALERT_INTERVIEW_CLEAR_DESC = "You are about to mark the candidate's interview as cleared!"
    static MSG_GEN_ALERT_INTERVIEW_DEL_ADMIN =
        'You are about to remove the interview from the list! This action is irreversible'
    static MSG_GEN_ALERT_CAN_JOINED =
        'You are about to change the candidate status as joined. This action is irreversible!'
    static MSG_AUTH_USER_PWD_CHANGED =
        'Your password has been updated successfully! Next time, please login with your new password.'
    static MSG_DELETE_JOB_CONFIRM = 'You are about to delete the job! This will un-list the job from Website.'
    static MSG_SEND_SUB_HR_CONTRACT_EMAIL = 'A mail with contract has been sent successfully to subHR!'
    static MSG_NOT_SEND_SUB_HR_CONTRACT_EMAIL = 'There is some issue in sending email to subHR!'

    static MSG_SEND_BUY_SERVICE_CONTACT_EMAIL = 'Thank you for your interest with us. We will get back you soon!'
    static MSG_NOT_SEND_BUY_SERVICE_CONTACT_EMAIL = 'There is some issue in sending email. Please try again!'
    static MSG_COMPANY_NAME_MAX_ALLOWED_CHAR = 'Company name exceeds 20 characters limit!'

    static MSG_COMMENT_DELETE_SUCCESS = 'The comment from post has been deleted!'
    static MSG_DELETE_COMMENT_APPROVE_CONFIRM = 'Are you sure you want to delete this comment !'

    static MAJOR_SKILLS_MAX_LENGTH = 10
    static MAJOR_SKILLS_EXCEED_WARNING_MESSAGE = 'Max limit reached !'
    static MAX_ITEMS_SELECTED = 10000

    // Misc
    static MISC_ADMIN_ACCESS_SEPARATOR = '----'

    // list of pages for which slug page will display
    static PAGES_DISPLAY_SITEMAP_FOOTER = ['/', '/contact-us']

    // URL of static image path of digital ocean
    static STATIC_IMAGE_URL = process.env.REACT_APP_STATIC_IMAGE_URL

    static SSO_URL = process.env.REACT_APP_SSO_URL || 'https://sso.client.imejob.com/auth/login'
    static APP_URL = process.env.REACT_APP_URL || 'http://localhost:3006'
    static ROUTE_CLIENT_AUTH_CALLBACK = '/admin/auth/callback'
    static API_URL = process.env.REACT_APP_API_URL || 'http://localhost:1130'
    static SOCKET_HOST = process.env.REACT_APP_SOCKET_SERVER_URL || 'ws://localhost:1130'
    static API_VERSION = process.env.REACT_APP_API_VER || '1'

    // methods needs to parse in axios request
    static METHOD_GET = 'GET'
    static METHOD_POST = 'POST'
    static METHOD_PUT = 'PUT'
    static METHOD_PATCH = 'PATCH'
    static METHOD_DELETE = 'DELETE'

    static ALLOWED_IMAGE_MIME_TYPE = ['image/png', 'image/jpeg', 'image/jpg']
    static REGEX__VALIDATE_URL =
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
}

export default Constants
